<template>
  <section
    id="entity-users-list"
    v-loading.fullscreen.lock="downloadLoading"
    :element-loading-text="loadingText"
  >
    <div class="inner-navbar" v-if="!hideActions">
      <el-row>
        <el-col :lg="16" :md="14" :sm="12" :xs="24">
          <div class="icon-block d-flex align-center">
            <div class="icon-text pl-1">
              <div class="d-flex mr-1">
                <el-button
                  v-if="selectedRowsIds.length"
                  class="add-buttons mt-0"
                  style="padding: 6px 10px !important"
                  @click="getFieldsForBulkUpdate"
                >
                  <i class="el-icon-edit pr-1"></i>
                  Update
                </el-button>
                <QuickEntityFilters
                  v-if="showQuickFilters"
                  :filterFields="
                    [...currentFilter.quick_filters, ...relationQuickFilters] ||
                    []
                  "
                  :allFields="selectedEntityFields"
                  :selectedFileds="selectedFileds"
                  @quick-filter-updated="quickFilterUpdated"
                ></QuickEntityFilters>
              </div>
              <h3
                class="table-head-text"
                v-if="
                  relationshipDetails && relationshipDetails.relationship_title
                "
              >
                {{ hideLabel ? "" : relationshipDetails.relationship_title }}
              </h3>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="10" :sm="12" :xs="24">
          <div
            class="right-block float-right"
            v-if="
              !loading &&
              checkAssignPermission() &&
              ((relationshipDetails && !relationshipDetails.allowOne) ||
                allowOneData == null)
            "
          >
            <assign-entity-reationship-data-widget
              :relationshipDetails="relationshipDetails"
              @entityDataAssigned="fetchParentRelatedChildEntityData"
              :existedData="existedDataIds"
              :unAssignedFieldDetails="unAssignedFieldDetails"
              :childEntityDataById="childEntityDataById"
              :totalRecords="total"
              :currentEntityData="currentEntityData"
              :filter="currentFilter"
              :relationQuickFilters="relationQuickFilters"
            ></assign-entity-reationship-data-widget>
          </div>

          <div
            class="right-block float-right"
            v-if="
              !loading &&
              checkAddNewPermission() &&
              ((relationshipDetails && !relationshipDetails.allowOne) ||
                allowOneData == null)
            "
          >
            <add-entity-reationship-data-widget
              :relationshipDetails="relationshipDetails"
              @entityDataAssigned="fetchParentRelatedChildEntityData"
              :existedData="existedDataIds"
              :childEntityDataById="childEntityDataById"
              :totalRecords="total"
              :currentEntityData="currentEntityData"
            ></add-entity-reationship-data-widget>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="nextRealationshipDetails && !loading && !hideLabel">
        <el-col :lg="24" :md="24" :sm="24" :xs="24">
          <el-button
            class="right-block float-right mt-10 button-uppercase"
            style="padding: 6px 6px !important"
            size="mini"
            @click="gotoNextStep"
          >
            Next
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="top">
      <div
        class="vue-data-table-default"
        v-if="relationshipDetails && !relationshipDetails.allowOne"
      >
        <!-- <el-input
              size="mini"
              placeholder="Search"
              v-model="search_string"
              @input="searchData"
              clearable
              prefix-icon="el-icon-search"
              style="width:250px"
            >
            </el-input> -->
        <data-tables-server
          :data="data"
          :total="total"
          :resizable="true"
          border
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          @selection-change="handleSelectionChange"
          v-loading="loading"
          :element-loading-text="loadingText"
          @cell-dblclick="cellDoubleClicked"
        >
          <el-scrollbar wrap-style=" max-width: calc(100% - 0px); ">
            <el-table-column
              v-if="checkbulkUpdatePermission"
              type="selection"
              width="55"
              fixed="left"
            ></el-table-column>
            <el-table-column
              v-if="
                relationshipDetails &&
                relationshipDetails.permissions &&
                relationshipDetails.permissions.total == true
              "
              :min-width="data.length == numberOfRecords ? '60' : '150'"
              label="S.No"
              fixed="left"
            >
              <template slot-scope="scope">
                <span v-if="scope.$index < numberOfRecords">{{
                  pageSize * (currentPage - 1) + scope.$index + 1
                }}</span>
                <span v-else-if="scope.$index == numberOfRecords"
                  >Page Total</span
                >
                <span v-else>Grand Total</span>
              </template>
            </el-table-column>
            <el-table-column
              min-width="170"
              v-for="step of getFilterColumList"
              :label="step.label"
              :key="step.template_id + '-' + step.id + '-' + step.label"
            >
              <template slot-scope="scope">
                <div :style="getFieldStyle(step, scope.row)">
                  <div v-if="step && isSystemColumn(step)">
                    <div>
                      <span
                        class="black-coral sm-100"
                        v-if="scope.row.updated_at && step.id == 'updated_at'"
                        >{{ scope.row.updated_at | globalDateTimeFormat }}</span
                      >
                      <span
                        class="black-coral sm-100"
                        v-if="scope.row.created_at && step.id == 'created_at'"
                        >{{ scope.row.created_at | globalDateTimeFormat }}</span
                      >
                      <span
                        class="black-coral sm-100"
                        v-if="
                          scope.row.created_by &&
                          step.id == 'created_by' &&
                          entityUserInfoData?.[scope.row.created_by]
                        "
                      >
                        {{
                          entityUserInfoData?.[scope.row.created_by]
                            .first_name +
                          " " +
                          entityUserInfoData?.[scope.row.created_by].last_name
                        }}
                      </span>
                      <span
                        class="black-coral sm-100"
                        v-if="
                          scope.row.updated_by &&
                          step.id == 'updated_by' &&
                          entityUserInfoData?.[scope.row.updated_by]
                        "
                      >
                        {{
                          entityUserInfoData?.[scope.row.updated_by]
                            .first_name +
                          " " +
                          entityUserInfoData?.[scope.row.updated_by].last_name
                        }}
                      </span>

                      <!-- profile picture with name in entity data table -->
                      <div
                        v-if="
                          currentEntity.entity_type == 'INDIVIDUAL' &&
                          step.id == 'profile_picture_with_name'
                        "
                      >
                        <div class="icon-container">
                          <div class="icon-block initial">
                            <div
                              v-if="
                                scope.row &&
                                scope.row.contact_id &&
                                getProfilePicture(scope.row)
                              "
                            >
                              <img
                                :src="getProfilePicture(scope.row)"
                                alt="IMG"
                                width="35"
                                height="35"
                                style="border-radius: 50%; margin-top: 2px"
                              />
                            </div>
                            <div class="icon" v-else>
                              <div class="initial-text">
                                <span
                                  v-if="
                                    scope.row &&
                                    getStandardTemplateInfo(scope.row)
                                  "
                                >
                                  {{
                                    getInitials(
                                      getStandardTemplateInfo(scope.row)
                                    )
                                  }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="pl-2">
                            <span v-if="scope.row">
                              {{
                                getStandardTemplateInfo(scope.row).replace(
                                  ",",
                                  " "
                                )
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      scope.$index >= numberOfRecords &&
                      scope.row.entityData &&
                      scope.row.entityData[step.template_id] &&
                      scope.row.entityData[step.template_id][step.id]
                    "
                  >
                    <span style="font-weight: 500">
                      {{ scope.row.entityData[step.template_id][step.id] }}
                    </span>
                  </div>
                  <div v-else>
                    <div v-if="step && step.type === 'PAY_BUTTON'">
                      {{ getFieldValue(scope.row, step) }}
                    </div>
                    <div v-else-if="step && step.type == 'DATE'">
                      <div
                        v-if="scope.$index == quickUpdateIndex"
                        class="pt-1 pb-1"
                      >
                        <el-date-picker
                          v-if="getDateViewType(getFieldForQuickUpdate(step))"
                          prefix-icon="el-icon-date"
                          v-model="
                            quickUpdateRowData[step.template_id][step.id]
                          "
                          :type="getDateViewType(getFieldForQuickUpdate(step))"
                          :disabled="
                            quickUpdateFieldDisabled(
                              step.template_id,
                              step.id,
                              childEntityDataById
                            )
                          "
                          :style="getStyleWidth(step)"
                        >
                        </el-date-picker>
                        <el-date-picker
                          v-else
                          prefix-icon="el-icon-date"
                          v-model="
                            quickUpdateRowData[step.template_id][step.id]
                          "
                          type="date"
                          :format="
                            globalDateFormat &&
                            globalDateFormat.includes('Do') == false
                              ? globalDateFormat
                                  .replace('YYYY', 'yyyy')
                                  .replace('DD', 'dd')
                              : 'MM-dd-yyyy'
                          "
                          :disabled="
                            quickUpdateFieldDisabled(
                              step.template_id,
                              step.id,
                              childEntityDataById
                            )
                          "
                          :style="getStyleWidth(step)"
                        >
                        </el-date-picker>
                      </div>
                      <div v-else>
                        {{ getFieldValue(scope.row, step) | globalDateFormat }}
                      </div>
                    </div>
                    <div v-else-if="step && step.type == 'DATE_TIME'">
                      <div
                        v-if="scope.$index == quickUpdateIndex"
                        class="pt-1 pb-1"
                      >
                        <el-date-picker
                          prefix-icon="el-icon-date"
                          v-model="
                            quickUpdateRowData[step.template_id][step.id]
                          "
                          type="datetime"
                          :format="globalDateTimeFormat"
                          placeholder="Select Date & Time"
                          :disabled="
                            quickUpdateFieldDisabled(
                              step.template_id,
                              step.id,
                              childEntityDataById
                            )
                          "
                          :style="getStyleWidth(step)"
                        >
                        </el-date-picker>
                      </div>
                      <div v-else>
                        {{
                          getFieldValue(scope.row, step) | globalDateTimeFormat
                        }}
                      </div>
                    </div>
                    <div
                      style="font-size: 13px"
                      v-else-if="step && step.type == 'MULTI_LINE_TEXT'"
                    >
                      <span
                        v-if="
                          step &&
                          step.type == 'MULTI_LINE_TEXT' &&
                          step.currency_code.data_type
                        "
                      >
                        <span v-if="getFieldValue(scope.row, step)">
                          <span
                            v-if="getFieldValue(scope.row, step).length > 100"
                          >
                            {{
                              truncateText(
                                getPlainText(getFieldValue(scope.row, step))
                              )
                            }}
                            <el-tooltip
                              :disabled="
                                isTipDisabled(
                                  getPlainText(getFieldValue(scope.row, step))
                                )
                              "
                              :content="
                                getFieldValue(scope.row, step).toString()
                              "
                              placement="top"
                              effect="dark"
                            >
                              <a href="#" style="color: #409eff">Read more</a>
                            </el-tooltip>
                          </span>
                          <span v-else>
                            {{ getPlainText(getFieldValue(scope.row, step)) }}
                          </span>
                        </span>
                      </span>
                    </div>
                    <div v-else-if="step && step.type == 'FILE'">
                      <div
                        v-if="
                          getFieldValue(scope.row, step) &&
                          getFieldValue(scope.row, step).length &&
                          getFieldValue(scope.row, step).length <= 1
                        "
                      >
                        <el-button
                          type="text"
                          style="margin-top: -14px"
                          @click="
                            downloadFile(getFieldValue(scope.row, step)[0])
                          "
                          class="mb-1"
                        >
                          <p style="font-size: 13px">
                            {{
                              getFieldValue(scope.row, step)[0].name
                                | truncate(20, "...")
                            }}
                            <i class="el-icon-download"></i>
                            <el-tooltip content="Preview" placement="bottom" v-if="getFieldValue(scope.row, step).length === 1">
                              <el-button
                                type="text"
                                style="margin-top: -10px; margin-left: 5px;"
                                @click="onPreview(getFieldValue(scope.row, step)[0])"
                              >
                                <i class="el-icon-view"></i>
                              </el-button>
                            </el-tooltip>
                          </p>
                        </el-button>
                      </div>
                      <div
                        class="d-flex"
                        v-else-if="
                          getFieldValue(scope.row, step) &&
                          getFieldValue(scope.row, step).length &&
                          getFieldValue(scope.row, step).length >= 1
                        "
                      >
                        <p
                          v-for="(file, index) of getFieldValue(
                            scope.row,
                            step
                          )"
                          :key="index"
                        >
                          <el-button
                            type="text"
                            style="margin-top: -10px"
                            v-if="file && file.name && index < limit"
                            @click="
                              downloadFile(getFieldValue(scope.row, step)[0])
                            "
                          >
                            {{ file.name | truncate(10, "...") }}
                            <i class="el-icon-download"></i>
                          </el-button>
                        </p>
                        <el-tag
                          style="cursor: pointer; width: 40px"
                          class="ml-1"
                          :value="getFieldValue(scope.row, step).length"
                          v-if="getFieldValue(scope.row, step).length > 1"
                          @click="downloadDocumentsPopup(scope.row, step)"
                        >
                          +{{ getFieldValue(scope.row, step).length - 1 }}
                        </el-tag>
                      </div>
                      <!-- </p> -->
                    </div>
                    <div v-else-if="step && step.type == 'SIGNATURE'">
                      <img
                        v-if="getFieldValue(scope.row, step)"
                        :src="getFieldValue(scope.row, step)"
                        alt="IMG"
                        width="100"
                        height="60"
                      />
                      <p v-else>-</p>
                    </div>
                    <div v-else-if="step && step.type == 'SINGLE_LINE_TEXT'">
                      <p v-if="data.input_type == 'MASKED'">xxxx</p>
                      <p
                        v-else-if="
                          step &&
                          step.currency_code &&
                          step.currency_code.type == 'HYPER_LINK'
                        "
                      >
                        <el-link
                          @click="checkNavigationAction(scope.row, step)"
                          style="color: rgb(69 188 237)"
                          >{{ getFieldValue(scope.row, step) }}</el-link
                        >
                      </p>
                      <div v-else style="font-size: 13px">
                        <div
                          v-if="scope.$index == quickUpdateIndex"
                          class="pt-1 pb-1"
                        >
                          <el-input
                            v-model="
                              quickUpdateRowData[step.template_id][step.id]
                            "
                            :disabled="
                              quickUpdateFieldDisabled(
                                step.template_id,
                                step.id,
                                childEntityDataById
                              )
                            "
                            :style="getStyleWidth(step)"
                          ></el-input>
                        </div>
                        <el-tooltip
                          v-else
                          :disabled="isDisabled(getFieldValue(scope.row, step))"
                          :content="getFieldValue(scope.row, step).toString()"
                          placement="top"
                          effect="dark"
                        >
                          <p>
                            {{ truncateText(getFieldValue(scope.row, step)) }}
                          </p>
                        </el-tooltip>
                      </div>
                    </div>
                    <div v-else-if="step && step.type == 'PHONE_COUNTRY_CODE'">
                      <span v-if="getFieldValue(scope.row, step)">{{
                        getFieldValue(scope.row, step)
                      }}</span>
                      <p v-else>-</p>
                    </div>
                    <div v-else-if="step && step.type == 'MULTI_SELECT'">
                      <div
                        v-if="scope.$index == quickUpdateIndex"
                        class="pt-1 pb-1"
                      >
                        <el-select
                          v-model="
                            quickUpdateRowData[step.template_id][step.id]
                          "
                          multiple
                          collapse-tags
                          :disabled="
                            quickUpdateFieldDisabled(
                              step.template_id,
                              step.id,
                              childEntityDataById
                            )
                          "
                          :style="getStyleWidth(step)"
                        >
                          <el-option
                            v-for="(option, index) of getFieldOptions(
                              step.template_id,
                              step.id,
                              childEntityDataById
                            )"
                            :label="option"
                            :value="option"
                            :key="index"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else>
                        <p style="font-size: 13px">
                          {{ getFieldValue(scope.row, step) | toMultiSelect }}
                        </p>
                      </div>
                    </div>
                    <div v-else-if="step && step.type == 'SELECT'">
                      <div
                        v-if="scope.$index == quickUpdateIndex"
                        class="pt-1 pb-1"
                      >
                        <el-select
                          v-model="
                            quickUpdateRowData[step.template_id][step.id]
                          "
                          :disabled="
                            quickUpdateFieldDisabled(
                              step.template_id,
                              step.id,
                              childEntityDataById
                            )
                          "
                          :style="getStyleWidth(step)"
                        >
                          <el-option
                            v-for="(option, index) of getFieldOptions(
                              step.template_id,
                              step.id,
                              childEntityDataById
                            )"
                            :label="option"
                            :value="option"
                            :key="index"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div v-else>
                        <p>{{ getFieldValue(scope.row, step) }}</p>
                      </div>
                    </div>
                    <div v-else-if="step && step.type == 'CHECKBOX'">
                      <div
                        v-if="scope.$index == quickUpdateIndex"
                        class="pt-1 pb-1"
                      >
                        <el-checkbox
                          v-model="
                            quickUpdateRowData[step.template_id][step.id]
                          "
                          :disabled="
                            quickUpdateFieldDisabled(
                              step.template_id,
                              step.id,
                              childEntityDataById
                            )
                          "
                        ></el-checkbox>
                      </div>
                      <div v-else>
                        {{ getFieldValue(scope.row, step) }}
                      </div>
                    </div>
                    <div v-else-if="step && step.type == 'ENTITY'">
                      <div
                        v-if="scope.$index == quickUpdateIndex"
                        class="pt-1 pb-1"
                      >
                        <EntityExecute
                          :data="getFieldForQuickUpdate(step)"
                          :form="quickUpdateRowData[step.template_id]"
                          :is-view="false"
                          :hasLabel="false"
                          :entityDataExists="true"
                          :fieldsData="[]"
                        >
                        </EntityExecute>
                      </div>
                      <div v-else>
                        <el-tooltip
                          :content="getFieldValue(scope.row, step)"
                          placement="top"
                        >
                          <p>
                            {{
                              getFieldValue(scope.row, step)
                                | truncate(15, "...")
                            }}
                          </p>
                        </el-tooltip>
                      </div>
                    </div>
                    <div v-else>
                      <el-tooltip
                        :disabled="isDisabled(getFieldValue(scope.row, step))"
                        :content="getFieldValue(scope.row, step).toString()"
                        placement="top"
                        effect="dark"
                      >
                        <p>
                          {{ truncateText(getFieldValue(scope.row, step)) }}
                        </p>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              min-width="180"
              fixed="right"
              v-if="
                !hideActions &&
                (checkViewPermission() ||
                  checkRedirectPermission() ||
                  checkAssignPermission())
              "
            >
              <template slot-scope="scope">
                <div v-if="scope.$index < numberOfRecords">
                  <div v-if="scope.$index == quickUpdateIndex">
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2 ml-2"
                      @click="quickUpdateIndex = -1"
                      title="Cancel"
                    >
                      <i class="el-icon-close"></i>
                    </el-button>
                    <el-button
                      plain
                      type="success"
                      size="mini"
                      class="px-2 ml-2"
                      @click="updateRow(scope.row)"
                      title="Save"
                    >
                      <i class="el-icon-check"></i>
                    </el-button>
                  </div>
                  <div v-else>
                    <el-tooltip content="Preview" placement="bottom">
                    <el-button
                      v-if="checkViewPermission()"
                      icon="el-icon-view"
                      circle
                      @click="goToEntityData(scope.row)"
                    ></el-button>
                    </el-tooltip>
                    <el-tooltip content="Redirect To" placement="bottom">
                    <el-button
                      v-if="checkRedirectPermission()"
                      icon="el-icon-top-right"
                      circle
                      @click="redirectToEntityData(scope.row)"
                    ></el-button>
                    </el-tooltip>
                    <el-tooltip content="Delete" placement="bottom">
                    <el-button
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      :disabled="!allowedDeleteForFieldRelationship"
                      @click="onDetachEntityData(scope.row)"
                      v-if="checkAssignPermission()"
                    ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-scrollbar>
        </data-tables-server>
        <div
          class="d-flex mb-2"
          v-if="aggregations && aggregations.length"
          style="float: right"
        >
          <div
            v-for="(aggregation, index) in aggregations"
            :key="index"
            class="ml-4"
          >
            <b>{{ aggregation.label }}</b
            ><br />
            <span>{{ aggregationValues[aggregation.label] }}</span>
          </div>
        </div>
      </div>
      <div v-else v-loading="loading">
        <div v-if="allowOneData != null">
          <div style="float: right">
            <el-tooltip content="Go-to" placement="top-start">
              <el-button
                v-if="checkRedirectPermission()"
                icon="el-icon-top-right"
                circle
                @click="redirectToEntityData(allowOneData)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="Detach" placement="top-start">
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                :disabled="!allowedDeleteForFieldRelationship"
                @click="onDetachEntityData(allowOneData)"
                v-if="checkAssignPermission()"
              ></el-button>
            </el-tooltip>
          </div>
          <fields-preview-template
            :templateData="childEntityDataById.templates[0].templateInfo"
            :has-next="false"
            :workflow-step-id="childEntityDataById.templates[0]._id"
            :entityDataId="allowOneData._id"
            :template-data-id="childEntityDataById.templates[0].templateDataId"
            :currentStep="childEntityDataById.templates[0]"
            :is-execute="false"
            layout="STANDARD"
            :currentEntity="childEntityDataById"
            :isEdit="false"
            :entityData="allowOneData"
            :currentMenu="null"
            :entityDataExists="true"
            :type="'s7aLF3NnAQg='"
          >
          </fields-preview-template>
        </div>
        <div v-else>
          <el-empty :image-size="50" description="No data"></el-empty>
        </div>
      </div>
    </div>
    <div>
      <dialog-component
        :modal="false"
        :visible="downloadDocuments"
        :title="'Download Documents'"
        :containerWidth="'35%'"
        :containerHeight="'45%'"
        @before-close="closeDownloadDocuments"
      >
        <!-- <el-row>
        <el-input
          class="search mb-1"
          placeholder="Search"
          v-model="searchDocument"
          clearable
          @input="searchDocumentName"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row> -->
        <div class="sidemenu mt-1 mb-10">
          <el-table
            border
            :data="documentsData"
            :header-row-class-name="'my-header-row'"
            class="tableSty"
            :show-header="true"
          >
            <el-table-column class="labelSty" label="Document Name">
              <template slot-scope="scope">
                <p style="font-size: 14px" slot="reference">
                  {{ scope.row.name }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Action" width="150">
              <template slot-scope="scope">
                <ul class="action-buttons">
                  <li>
                    <button class="btn btn-light btn-sm m-lr-1" type="button">
                      <span
                        class="text-muted"
                        @click="onPreview(scope.row)"
                        title="preview"
                      >
                        <i class="fa fa-eye"></i>
                      </span>
                    </button>
                  </li>
                  <li>
                    <button class="btn btn-light btn-sm m-lr-1" type="button">
                      <span
                        class="text-muted"
                        @click="onDownloadFile(scope.row)"
                        target="_blank"
                        ref="downloadLink"
                      >
                        <i class="el-icon-download"></i>
                      </span>
                    </button>
                  </li>
                </ul>

                <!-- <el-button
                @click="onDownloadFile(scope.row)"
                target="_blank"
                ref="downloadLink"
                style="width: 120px; font-size: 12px"
              >
                Download
                <i class="el-icon-download"></i>
              </el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>

        <span slot="footer">
          <el-button @click="downloadDocuments = false"> Close </el-button>
        </span>
      </dialog-component>
    </div>
    <complete-entity-data-popup
      v-if="showRelationshipDataDialog"
      :entityId="relationshipDetails.relationship_id"
      :entityDataId="relationshipDataId"
      :entityRefName="childEntityDataById.name"
      :primaryDataValue="childEntityDataById.name"
      :isMultiple="false"
      :isTitleVisible="false"
      @close="showRelationshipDataDialog = false"
    >
    </complete-entity-data-popup>
    <dialog-component
      :title="multiLineTextLabel"
      :visible="openMultiLineTextDialog"
      @before-close="closeMultiLineData"
      :isShowFooter="false"
      :containerMaxWidth="'50vw'"
    >
      <div class="desription-text" v-if="isRichText">
        <ckeditor :config="editorConfig" v-model="multiLineTextData">
        </ckeditor>
      </div>
      <div class="desription-text" v-else>
        {{ multiLineTextData | truncateHTML() }}
      </div>
    </dialog-component>
    <dialog-component
      :visible="bulkUpdateVisible"
      :title="'Update'"
      @before-close="resetBulkUpdate"
      :containerWidth="'30%'"
      :containerHeight="'30%'"
    >
      <div class="d-flex mt-1 mb-2">
        <div>
          <label>Field</label><br />
          <el-select
            v-model="bulkUpdateField"
            clearable
            @change="fetchOptions"
            filterable
          >
            <el-option
              v-for="(field, index) in bulkUpdateFields"
              :key="index"
              :value="field._id + '#' + field.input_type + '#' + field.key"
              :label="field.label"
            >
            </el-option>
          </el-select>
        </div>
        <div class="ml-1">
          <label>Value</label><br />
          <el-select
            :multiple="bulkUpdateField.split('#')[1] == 'MULTI_SELECT'"
            v-model="bulkUpdateValue"
            filterable
            clearable
            :loading="optionsLoading"
            loading-text="Loading..."
          >
            <el-option
              v-for="(op, i) in bulkUpdateOptions"
              :key="i"
              :value="op.value"
              :label="op.label"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="resetBulkUpdate">
          <i class="fa fa-close"></i> Cancel</el-button
        >
        <el-button
          size="mini"
          type="primary"
          :disabled="bulkUpdateField == '' || bulkUpdateValue == ''"
          @click="bulkUpdate"
        >
          <i class="fa fa-sign-out"></i> Update
        </el-button>
      </span>
    </dialog-component>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/config/app";
// import Vue from "vue";
// import VueLodash from "vue-lodash";
// import lodash from "lodash";
import * as mexp from "math-expression-evaluator";
import AssignEntityReationshipDataWidget from "../widgets/AssignEntityReationshipDataWidget.vue";
import AddEntityReationshipDataWidget from "../widgets/AddEntityReationshipDataWidget.vue";
import EntityHelper from "@/mixins/EntitiesHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import moment from "moment";
import { postAPICall } from "@/helpers/httpHelper";
import { fetchEntityById } from "@/repo/entityRepo";
import { fetchFilterById } from "@/repo/filtersRepo";
import FieldStylesHelper from "../../mixins/FieldStylesHelper";
import { isEqual } from "@/helpers/lodashHelper";
export default {
  name: "EntityDataList",
  components: {
    AssignEntityReationshipDataWidget,
    AddEntityReationshipDataWidget,
    CompleteEntityDataPopup: () => import("./completeEntityDataPopup.vue"),
    FieldsPreviewTemplate: () =>
      import("../templates/templateFieldsPreview.vue"),
    QuickEntityFilters: () =>
      import("@/components/widgets/entityQuickFilters.vue"),
    EntityExecute: () =>
      import("@/components/templates/formComponentsExecute/EntityExecute.vue"),
  },
  mixins: [
    EntityHelper,
    entityRelationshipMixin,
    CustomDashboardConfig,
    TemplateBuilderhelper,
    FieldStylesHelper,
  ],
  props: [
    "relationshipDetails",
    "nextRealationshipDetails",
    "hideLabel",
    "currentEntityData",
    "hideActions",
  ],
  data() {
    return {
      isLengthy: false,
      filterText: "",
      loading: false,
      total: 0,
      currentPage: 1,
      entityDataId: null,
      pageSize: 0,
      data: [],
      documentsLoading: false,
      firstTemplateId: null,
      primaryField: "",
      primaryKey: "",
      dialogTableVisible: false,
      entity_id: "",
      props: {
        children: "children",
        label: "label",
        disabled: "disabled",
      },

      fieldsListData: [],
      defaultKeys: [],
      selectedTableFields: [],
      filterColumList: [],
      checkedFields: [],

      parentRelatedChildEntityData: {
        child_entity_data_ids: [],
      },
      childEntityDataById: null,
      selectedEntityFields: [],
      selectedEntityFieldsObject: {},
      existedDataIds: [],
      allowedDeleteForFieldRelationship: true,
      removableFieldsKeys: [],
      unAssignedFieldDetails: null,
      isApplicationUserSide: false,
      showEntityDataInPopup: false,
      entityData: null,
      numberOfRecords: 0,
      weekDayMap: {
        MON: "Monday",
        TUE: "Tuesday",
        WED: "Wednesday",
        THU: "Thursday",
        FRI: "Friday",
        SAT: "Saturday",
        SUN: "Sunday",
      },
      downloadDocuments: false,
      documentsData: [],
      limit: 1,
      loadingText: "Downloading...",
      downloadLoading: false,
      showRelationshipDataDialog: false,
      relationshipDataId: "",
      currentEntity: {},
      allowOneData: null,
      aggregations: [],
      aggregationValues: {},
      multiLineTextData: "",
      multiLineTextLabel: "",
      openMultiLineTextDialog: false,
      isRichText: false,
      entityUserInfoData: {},
      selectedRowsIds: [],
      bulkUpdateFields: [],
      bulkUpdateVisible: false,
      bulkUpdateField: "",
      bulkUpdateValue: "",
      bulkUpdateOptions: [],
      optionsLoading: false,
      currentFilter: null,
      relationQuickFilters: [],
      selectedFileds: {},
      appliedQuickFilters: [],
      search_string: "",
      defaultSettingFields: [],
      quickUpdateIndex: -1,
      quickUpdateRowData: null,
    };
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      // "getChildEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getEntityPrimaryData",
      // "entityPrimaryData",
    ]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
      "getDuplicateDataCheck",
    ]),

    ...mapGetters("entityRelationships", [
      "getEntityRelationshipDataStatus",
      "getEntityRelationshipDataAssignStatus",
      "getEntityRelationshipDataDetachStatus",
      "getParentEntityRelatiponshipData",
    ]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    paginationProps() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    getEntityRoute() {
      return "/entity-execute/" + this.$route.params.entity_id;
    },
    logoUploadUrl() {
      return config.S3_BUCKET_URL;
    },

    getFilterColumList() {
      return this.filterColumList;
    },
    checkbulkUpdatePermission() {
      if (this.relationshipDetails?.permissions?.bulkUpdate) {
        return true;
      }
      return false;
    },
    checkRowEditPermission() {
      if (this.relationshipDetails?.permissions?.rowEdit) {
        return true;
      }
      return false;
    },
    showQuickFilters() {
      return (
        this.currentFilter?.quick_filters?.length ||
        this.relationQuickFilters.length
      );
    },
  },
  async mounted() {
    this.loading = true;
    this.loadingText = "Fetching data...";
    this.relationshipDetails["permissions"] = {
      ...{
        add: true,
        assign: true,
        redirect: true,
        view: true,
        bulkUpdate: true,
        rowEdit: true,
        assign_type: "default_view",
      },
      ...this.relationshipDetails?.["permissions"],
    };
    this.search_string = "";
    if (
      this.$route.name == "ApEntityExecute" ||
      this.$route.name == "ApPreviewEntityData"
    ) {
      this.isApplicationUserSide = true;
    }
    let limit = (this.pageSizes && this.pageSizes[0]) || 10;
    this.pageSize = limit;
    this.entity_id = this.$route.params.entity_id;
    this.quickUpdateIndex = -1;
    this.quickUpdateRowData = null;
    if (
      this.relationshipDetails?.filter != "" &&
      this.relationshipDetails?.filter
    ) {
      this.currentFilter = await fetchFilterById(
        this.relationshipDetails.filter
      );
      await this.getRelationQuickFilters();
    }
    if (this.relationshipDetails.relationship_id) {
      await this.getNecessaryInfo();

      await this.fetchParentRelatedChildEntityData();
    }
  },

  methods: {
    getFieldForQuickUpdate(step) {
      let field = this.childEntityDataById?.templates
        ?.find((e) => e.template_id == step.template_id)
        .templateInfo.sections[0]?.fields.find(
          (e) => e.key &&  step.id == e.key.includes('#') ? e.key.split("#")[1] : e.key
        );
      return {
        ...field,
        key: field.key.split("#")[1],
      };
    },
    cellDoubleClicked(row) {
      if (!this.checkRowEditPermission) {
        this.$message({
          message: "You don't have permission to edit the data",
          type: "warning",
        });
        return;
      }
      this.quickUpdateIndex = this.data.findIndex(
        (e) => e._id.toString() == row._id.toString()
      );
      this.quickUpdateRowData = null;
      this.quickUpdateRowData = JSON.parse(JSON.stringify(row.entityData));
      if (
        this.childEntityDataById?.templates.length !==
        Object.keys(row.entityData)
      ) {
        this.childEntityDataById?.templates.map((tmp) => {
          if (!Object.keys(this.quickUpdateRowData).includes(tmp.template_id)) {
            let emptyTemplateDataObj = {};
            tmp.templateInfo.sections[0].fields.map((fd) => {
              emptyTemplateDataObj[fd.key] = "";
            });
            this.quickUpdateRowData[tmp.template_id] = emptyTemplateDataObj;
          }
        });
      }
    },
    async updateRow(row) {
      this.loading = true;
      await Promise.all(
        this.childEntityDataById?.templates.map(async (temp) => {
          if (
            !Object.values(this.quickUpdateRowData[temp.template_id]).every(
              (value) => value == null || value === ""
            ) &&
            !isEqual(
              row.entityData[temp.template_id],
              this.quickUpdateRowData[temp.template_id]
            )
          ) {
            this.loadingText = "Checking duplicate data...";
            await this.$store.dispatch("templatesData/checkDuplicateData", {
              data: [
                {
                  form: this.quickUpdateRowData[temp.template_id],
                  template_id: temp.template_id,
                },
              ],
              entity_id: this.childEntityDataById._id,
              parent_entityDataId: row._id || null,
            });
            if (this.getDuplicateDataCheck) {
              if (!this.getDuplicateDataCheck?.allowed) {
                this.$notify.warning({
                  title: "Warning",
                  message: "Duplicate data found",
                });
                this.loading = false;
                this.loadingText = "Loading...";
                return;
              }
            }
            this.loadingText = "Updating data...";
            await this.$store.dispatch("templatesData/updateUserTemplateData", {
              template_data: this.quickUpdateRowData[temp.template_id],
              entity_data_id: row._id,
              template_id: temp.template_id,
              entity_id: this.childEntityDataById._id,
            });
          }
        })
      );
      this.quickUpdateIndex = -1;
      this.loading = false;
      await this.fetchParentRelatedChildEntityData();
    },
    async getRelationQuickFilters() {
      const relationFilters = this.currentFilter?.filters?.filter(
        (e) => e.data_type == "RELATION"
      );
      if (relationFilters.length) {
        let relationFilter = await postAPICall(
          "GET",
          `entity-filter/${relationFilters[0].value}`
        );
        if (relationFilter?.data?.quick_filters?.length) {
          this.relationQuickFilters = relationFilter?.data?.quick_filters?.map(
            (e) => e + "#" + relationFilters[0].relation_entity_id
          );
        }
      }
    },
    quickFilterUpdated(data) {
      this.appliedQuickFilters = [...data];
      this.fetchParentRelatedChildEntityData();
    },
    searchData() {
      this.fetchParentRelatedChildEntityData();
    },
    getPlainText(htmlContent) {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = htmlContent;
      return tempElement.textContent || tempElement.innerText || "";
    },
    handleSelectionChange(checkedDocs) {
      this.selectedRowsIds = checkedDocs;
    },
    async getFieldsForBulkUpdate() {
      this.bulkUpdateFields = [];
      this.childEntityDataById.templates.map((temp) => {
        this.bulkUpdateFields = [
          ...this.bulkUpdateFields,
          ...temp.templateInfo.sections[0].fields
            .filter(
              (fd) =>
                ["SELECT", "MULTI_SELECT", "ENTITY", "CHECKBOX"].includes(
                  fd.inputType
                ) && fd?.entity_id != this.childEntityDataById._id
            )
            .map((fd) => {
              return { ...fd, ...{ template_id: temp.template_id } };
            }),
        ];
      });
      this.bulkUpdateVisible = true;
    },
    async fetchOptions() {
      this.bulkUpdateOptions = [];
      this.bulkUpdateValue = "";
      if (this.bulkUpdateField != "") {
        this.optionsLoading = true;
        let field = this.bulkUpdateFields.find(
          (fd) => fd._id == this.bulkUpdateField.split("#")[0]
        );
        let entityData;
        switch (field.inputType) {
          case "SELECT":
          case "MULTI_SELECT":
            this.bulkUpdateOptions = field.options.map((op) => {
              return {
                label: op,
                value: op,
              };
            });
            break;
          case "CHECKBOX":
            this.bulkUpdateOptions = [
              {
                label: "Checked",
                value: true,
              },
              {
                label: "Unchecked",
                value: false,
              },
            ];
            break;
          case "ENTITY":
            entityData = await postAPICall(
              "POST",
              "/entities-data/entity/data",
              {
                entity_id: field.entity_id,
              }
            );
            if (entityData?.data?.length) {
              entityData.data.map((dt) => {
                this.bulkUpdateOptions.push({
                  label: field?.primary_fields
                    .map(
                      (pf) =>
                        dt?.entityData?.[pf.split("#")[0]]?.[pf.split("#")[1]]
                    )
                    .join(" - "),
                  value:
                    field?.primary_fields
                      .map(
                        (pf) =>
                          dt?.entityData?.[pf.split("#")[0]]?.[pf.split("#")[1]]
                      )
                      .join(" - ") +
                    "#" +
                    dt._id,
                });
              });
            }
            break;
        }
        this.optionsLoading = false;
      }
    },
    async bulkUpdate() {
      this.bulkUpdateVisible = false;
      this.loading = true;
      this.loadingText = "Updating...";
      let selectedIds = this.selectedRowsIds.map((row) => {
        return row._id;
      });
      let params = {
        field: this.bulkUpdateField,
        value: this.bulkUpdateValue,
        ids: selectedIds,
        entity_id: this.childEntityDataById._id,
      };
      let response = await postAPICall(
        "POST",
        "entities-data/bulk-update",
        params
      );
      if (response.success) {
        this.$notify.success({
          title: "Success",
          message: "Your data updated successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in updating the data",
        });
      }
      this.resetBulkUpdate();
      this.loadingText = "Fetching data...";
      await this.fetchParentRelatedChildEntityData();
    },
    resetBulkUpdate() {
      this.bulkUpdateField = "";
      this.bulkUpdateValue = "";
      this.bulkUpdateOptions = "";
      this.bulkUpdateVisible = false;
    },
    truncateText(text) {
      const maxLength = 12;
      if (text.length > maxLength) {
        this.isLengthy = true;
        return text.substring(0, maxLength) + "...";
      } else {
        this.isLengthy = false;
        return text;
      }
    },
    isDisabled(text) {
      const maxLength = 50;
      if (text.length > maxLength) {
        return false;
      } else {
        return true;
      }
    },
    isTipDisabled(text) {
      const maxLength = 10;
      if (text.length > maxLength) {
        return false;
      } else {
        return true;
      }
    },
    openMultiLineData(htmlContent, label, step) {
      this.openMultiLineTextDialog = true;
      this.multiLineTextData = htmlContent;
      this.multiLineTextLabel = label;
      if ("currency_code" in step && "data_type" in step.currency_code) {
        this.isRichText = false;
      } else {
        this.isRichText = true;
      }
    },
    closeMultiLineData() {
      this.openMultiLineTextDialog = false;
      this.isRichText = null;
    },

    async downloadFile(data) {
      this.downloadLoading = true;
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError || "Error while downloading",
        });
      }
      this.downloadLoading = false;
    },
    setAllowOne() {
      this.relationshipDetails.allowOne = null;

      if (this.relationshipDetails.representation == "TAB") {
        if (
          (this.relationshipDetails.owner_type == "CHILD" &&
            this.relationshipDetails.parent_relation_type == "ONE") ||
          (this.relationshipDetails.owner_type == "PARENT" &&
            this.relationshipDetails.child_relation_type == "ONE")
        ) {
          this.relationshipDetails.allowOne = true;
        } else {
          this.relationshipDetails.allowOne = false;
        }
      } else {
        if (this.relationshipDetails.parent_relation_type == "MANY") {
          this.relationshipDetails.allowOne = false;
        } else {
          this.relationshipDetails.allowOne = true;
        }
      }
    },

    gotoNextStep() {
      let url = this.getStepEntityRelationshipUrl(
        this.nextRealationshipDetails
      );
      this.$router.push(url);
    },
    assignEntityData() {
      this.$router.push(`/entity-execute/${this.entity_id}`);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    getFieldValue(row, step) {
      const field =
        this.selectedEntityFieldsObject[`${step.template_id}#${step.id}`];
      if (!row.entityData?.[step.template_id]?.[step.id]) {
        if (
          field &&
          field.properties &&
          field.properties.tableDefaultValue &&
          field.properties.tableDefaultValue !== ""
        ) {
          return field?.properties?.tableDefaultValue;
        }
      }
      let checkIsGlobalvariable = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.id
      );
      if (
        checkIsGlobalvariable &&
        checkIsGlobalvariable.is_global_variable &&
        checkIsGlobalvariable.global_variable_id
      ) {
        let globalVariable = (this.globalVariablesData || []).find(
          (e) => e._id == checkIsGlobalvariable.global_variable_id
        );
        if (globalVariable) {
          if (
            globalVariable.input_type == "IMAGE" &&
            globalVariable.image_url
          ) {
            return globalVariable.image_url;
          } else if (globalVariable.value) return globalVariable.value;
        }
      }
      if (step.type == "CHECKBOX") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id]
        ) {
          switch (row?.entityData?.[step.template_id]?.[step.id]) {
            case true:
              if (
                field &&
                field.properties &&
                field.properties.checkedLabel &&
                field.properties.checkedLabel !== ""
              ) {
                return field.properties.checkedLabel;
              }
              return true;
            case false:
              if (
                field &&
                field.properties &&
                field.properties.unCheckedLabel &&
                field.properties.unCheckedLabel !== ""
              ) {
                return field.properties.unCheckedLabel;
              }
              return false;
          }
        }
      }
      if (
        field &&
        field.input_type == "PAYMENT_VARIABLE" &&
        row.entityData &&
        row.entityData[step.template_id] &&
        !row.entityData[step.template_id][step.id] &&
        step.type === "CURRENCY"
      ) {
        row.entityData[step.template_id][step.id] = 0;
      }
      if (step.type == "WEEKDAYS") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][step.id]
        ) {
          if (
            checkIsGlobalvariable?.allow_multiple &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            let mappedArray = row.entityData[step.template_id][step.id].map(
              (e) => this.weekDayMap[e]
            );
            return mappedArray.join(",");
          } else {
            return this.weekDayMap[row.entityData[step.template_id][step.id]];
          }
        }
        return "";
      } else if (step.type == "HTML_CONTENT") {
        let newfd = this.currentEntity.templates.find((el) => {
          if (el.template_id === step.template_id) {
            return el.templateInfo;
          }
        });
        let val = newfd.templateInfo.sections[0].fields.find((el) => {
          let keyVal = el.key.split("#")[1];
          if (keyVal === step.id && el.input_type == "HTML_CONTENT") {
            return el;
          }
        });

        return val.content;
        // return row.entityData[step.template_id][step.id]
      } else if (step.type == "IMAGE") {
        let id = this.generateKeyForField(step.label);
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][id]
        ) {
          return row.entityData[step.template_id][id];
        }
      } else if (
        (step.type == "AGGREGATE_FUNCTION" || step.type == "CURRENCY") &&
        row.entityData[step.template_id][step.id] != null &&
        row.entityData[step.template_id][step.id] != undefined
      ) {
        let field = this.selectedEntityFields.find(
          (e) => e.key === step.template_id + "#" + step.id
        );
        let data = this.getFieldValue1(
          field,
          row.entityData[step.template_id][step.id]
        );
        if (data) {
          return data;
        }
        return row.entityData[step.template_id][step.id];
      } else if (
        step.type == "FILE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        this.documentsData =
          row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
            ? row.entityData[step.template_id][step.id].files
            : row.entityData[step.template_id][step.id];
        return row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      } else if (
        step.type == "LIST" &&
        row.entityData[step.template_id][step.id]
      ) {
        let result,
          field =
            this.selectedEntityFieldsObject[`${step.template_id}#${step.id}`];
        if (!field) {
          field = this.selectedEntityFields.find(
            (e) => e.key === step.template_id + "#" + step.id
          );
        }
        if (field?.list_data) {
          let value = field.list_data.find(
            (e) =>
              e.value == row.entityData[step.template_id][step.id] &&
              (e.name == row.entityData[step.template_id][step.id + "_info"] ||
                !row.entityData[step.template_id][step.id + "_info"])
          );
          if (value && value.name) {
            result = value.name;
          } else {
            result = row.entityData[step.template_id][step.id];
          }
        } else {
          result = row.entityData[step.template_id][step.id];
        }
        if (result && typeof result == "object" && result.length) {
          result = result[0];
        }
        return result;
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id] != null &&
        row.entityData[step.template_id][step.id] != undefined
      ) {
        if (step.type == "FORMULA") {
          if (field.result_type) {
            step.type = field.result_type;
          }
        }
        if (
          typeof row.entityData[step.template_id][step.id] == "number" &&
          this.isFloat(row.entityData[step.template_id][step.id])
        ) {
          return parseFloat(row.entityData[step.template_id][step.id]).toFixed(
            2
          );
        }
        if (step.type == "MULTI_SELECT") {
          let value = row.entityData[step.template_id][step.id]
            ? row.entityData[step.template_id][step.id]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.join(",");
            }
            return value;
          }
          return "";
        } else if (
          step.type == "SELECT" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (
            field &&
            field.inputType == "SELECT" &&
            field.is_list &&
            field.global_variable_id
          ) {
            let value = field.options.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            }
            result = row.entityData[step.template_id][step.id];
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "LIST" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (field?.list_data) {
            let value = field.list_data.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row.entityData[step.template_id][step.id];
            }
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "NUMBER" &&
          row.entityData[step.template_id][step.id]
        ) {
          return typeof row.entityData[step.template_id][step.id] == "number"
            ? row.entityData[step.template_id][step.id]
            : "";
        } else if (step.type == "CHECKBOX_GROUP") {
          if (
            row.entityData &&
            step.template_id &&
            row.entityData[step.template_id] &&
            row.entityData[step.template_id][step.id] &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id].join(",")
              : "";
          } else {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id]
              : "-";
          }
        } else if (
          step.type == "TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "HH:mm:ss"
              ) +
              " - " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "HH:mm:ss"
              )
            );
          }
        } else if (
          step.type == "DATE_TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY hh:mm:ss A"
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY hh:mm:ss A"
              )
            );
          }
        } else if (
          step.type == "DATE_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY "
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY"
              )
            );
          }
        } else if (
          step.type == "PHONE_COUNTRY_CODE" &&
          row?.entityData[step.template_id][step.id]
        ) {
          let value = row.entityData[step.template_id][step.id];
          if (
            row.entityData[step.template_id] &&
            row.entityData[step.template_id][step.id + "_code"]
              ?.countryCallingCode
          ) {
            value =
              "+" +
              row.entityData[step.template_id][step.id + "_code"]
                .countryCallingCode +
              " " +
              value;
            return value;
          } else {
            return value;
          }
        } else if (
          step.type == "CURRENCY" &&
          row.entityData[step.template_id][step.id] != null &&
          row.entityData[step.template_id][step.id] != undefined
        ) {
          let field =
            this.selectedEntityFieldsObject[`${step.template_id}#${step.id}`];
          let data = this.getFieldValue1(
            field,
            row.entityData[step.template_id][step.id]
          );
          if (data) {
            return data;
          }
          return row.entityData[step.template_id][step.id];
        }
        if (row.entityData[step.template_id][step.id + "/name"]) {
          if (
            typeof row.entityData[step.template_id][step.id + "/name"] ==
              "object" &&
            row.entityData[step.template_id][step.id + "/name"].length
          ) {
            return row.entityData[step.template_id][step.id + "/name"].join(
              ","
            );
          } else if (
            this.checkDate(row.entityData[step.template_id][step.id + "/name"])
          ) {
            const format = this.getDefaultDateFormat
              ? this.getDefaultDateFormat
              : "MM-DD-YYYY";
            return moment(
              row.entityData[step.template_id][step.id + "/name"]
            ).format(format);
          }
          return row.entityData[step.template_id][step.id + "/name"];
        } else {
          return row.entityData[step.template_id][step.id];
        }
      }
      return "";
    },
    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    prepareParams() {
      return {
        name: this.getEntityDataById.name,
        description: this.getEntityDataById.description,
        entity_type: this.getEntityDataById.entity_type,
        templates: this.getEntityDataById.templates,
        id: this.getEntityDataById._id,
        primaryFields: this.getEntityDataById.primaryFields,
        settings: this.selectedFieldKeys,
      };
    },
    async update() {
      let checked = this.$refs.tree.getCheckedNodes();

      // let selectedEntity = await lodash.filter(checked, function (node) {
      //   return !node.children;
      // });
      let selectedEntity = checked.filter((node) => !node.children);
      let fields = selectedEntity.filter((e) => !e.fields);
      this.selectedFieldKeys = fields.map((field) => field);

      let params = this.prepareParams();
      await this.$store.dispatch("entities/updateEntityById", params);

      if (this.getEntityDataUpdateStatus) {
        this.loading = false;
        this.dialogTableVisible = false;
        this.$notify({
          title: "Success",
          message: "Settings saved",
          type: "success",
        });
      } else {
        this.dialogTableVisible = true;
        this.$notify({
          title: "Error",
          message: "Failed to Save the Settings",
          type: "error",
        });
        this.loading = false;
      }
    },
    checkedNodes(event) {
      this.updateNodesData(event);
    },
    updateNodesData(event) {
      if (this.selectedTableFields.indexOf(event.id) !== -1) {
        const index = this.selectedTableFields.indexOf(event.id);
        if (index > -1) {
          this.selectedTableFields.splice(index, 1);
        }
        // this.filterColumList = this._.filter(
        //   this.filterColumList,
        //   function (o) {
        //     return o.id != event.id;
        //   }
        // );
        this.filterColumList = this.filterColumList.filter(
          (o) => o.id != event.id
        );
      } else {
        this.filterColumList.push(event);
        this.selectedTableFields.push(event.id);
      }
    },

    async fetchParentRelatedChildEntityData() {
      try {
        this.loading = true;

        this.setAllowOne();

        let params = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          parent_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
        };

        if (this.relationshipDetails.owner_type == "CHILD") {
          params = {
            parent_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_id: this.relationshipDetails.parent_entity_id,
          };
        }

        await this.$store.dispatch(
          "entityRelationships/fetchParentEntityRelationshipData",
          params
        );

        this.parentRelatedChildEntityData = {
          ...this.getParentEntityRelatiponshipData,
        };

        if (
          this.parentRelatedChildEntityData &&
          this.parentRelatedChildEntityData.child_entity_data_ids &&
          this.parentRelatedChildEntityData.child_entity_data_ids.length
        ) {
          this.existedDataIds =
            this.parentRelatedChildEntityData.child_entity_data_ids;
          await this.fetchEntitiesDataForTable(
            this.parentRelatedChildEntityData.child_entity_data_ids
          );
        } else {
          this.data = [];
          this.existedDataIds = [];
          this.allowOneData = null;
        }
        this.loading = false;
      } catch (err) {
        console.log("fetchParentRelatedChildEntityData", err);
        this.loading = false;
        this.existedDataIds = [];
      }
    },

    async fetchChildEntityData(id) {
      try {
        this.loading = true;
        // await this.$store.dispatch("entities/fetchChildEntityById", id);
        const getChildEntityDataById = await fetchEntityById(id);
        this.childEntityDataById = { ...getChildEntityDataById };
        this.loading = false;
      } catch (err) {
        console.log("Relationship error:", err);
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      await this.fetchChildEntityData(this.relationshipDetails.relationship_id);

      if (this.childEntityDataById) {
        this.fieldsListData = [];
        this.filterColumList = [];
        this.selectedTableFields = [];
        let allFields = [];

        this.childEntityDataById.templates.map((data) => {
          let templateFields = [];
          if (
            data.templateInfo &&
            data.templateInfo.sections &&
            data.templateInfo.sections.length
          ) {
            data.templateInfo.sections[0].fields.map((field) => {
              if (this.relationshipDetails.representation == "FIELD") {
                if (
                  field.input_type == "ENTITY" &&
                  this.relationshipDetails.child_entity_id == field.entity_id
                ) {
                  if (field.validations.required) {
                    this.allowedDeleteForFieldRelationship = false;
                  } else {
                    this.allowedDeleteForFieldRelationship = true;
                    this.removableFieldsKeys = [
                      {
                        key: field.key,
                        template_id: data.template_id,
                        value: this.relationshipDetails.parent_entity_data_id,
                      },
                      {
                        key: field.key + "/name",
                        template_id: data.template_id,
                        value: this.entityDataTitle(),
                      },
                    ];

                    this.unAssignedFieldDetails = this.removableFieldsKeys;
                  }
                }
              }
              templateFields.push({
                label: field.label,
                id: field.key,
                template_id: data.template_id,
                type: field.inputType,
                currency_code: field.validations,
                entity_id: field.entity_id,
              });
            });
          }
          allFields.push(...templateFields);

          this.fieldsListData.push({
            label: data.templateInfo.name,
            id: data.templateInfo.slug + "ABC",
            template_id: data.template_id,
            children: [...templateFields],
          });
        });
        if (this.currentFilter?.columns_settings?.length) {
          this.filterColumList = [...this.currentFilter.columns_settings];
        } else if (
          this.childEntityDataById.settings &&
          this.childEntityDataById.settings.length
        ) {
          this.selectedTableFields = [];
          this.filterColumList = this.childEntityDataById.settings;
          //map with latest field type
          this.filterColumList = this.mapTableFieldsType(
            this.childEntityDataById,
            this.filterColumList
          );
          await this.childEntityDataById.settings.map(async (data) => {
            await this.selectedTableFields.push(data.id);
          });
        } else {
          // this.childEntityDataById.primaryFields.map(async (data) => {
          // let result = this._.filter(allFields, function (o) {
          //   return o.id === data.key;
          // });
          let result = allFields.filter(
            (o) =>
              o.template_id ===
              this.childEntityDataById?.templates?.[0].template_id
          );

          if (result && result.length) {
            this.filterColumList = [...this.filterColumList, ...result];
            result.map((data) => {
              this.selectedTableFields.push(data.key);
            });
          }
          this.defaultSettingFields = result;
          // });
        }
        if (this.relationshipDetails.representation == "FIELD") {
          this.filterColumList = this.filterColumList.filter(
            (e) =>
              !(
                (e.input_type == "ENTITY" || e.type == "ENTITY") &&
                this.relationshipDetails.child_entity_id == e.entity_id
              )
          );
        }
        this.currentEntity = this.childEntityDataById;
        this.selectedEntityFields = [];
        if (this.currentEntity && this.currentEntity.templates) {
          this.currentEntity.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(e.templateInfo),
              ];
            }
          });
        }
        this.selectedEntityFieldsObject = {};
        this.selectedEntityFields.map((e) => {
          this.selectedEntityFieldsObject[e.key] = e;
        });
      }
    },
    getPrimaryName(primaryField, item) {
      let name = "";
      let key =
        primaryField && primaryField["key"] ? primaryField["key"] : null;
      if (key) {
        if (key.includes("#")) {
          key = key.split("#")[1];
        }
        name =
          item &&
          item.entityData &&
          primaryField.template_id &&
          item.entityData[primaryField.template_id] &&
          item.entityData[primaryField.template_id][key]
            ? item.entityData[primaryField.template_id][key + "/name"] ||
              item.entityData[primaryField.template_id][key]
            : "-";
        if (
          !name &&
          item &&
          item.entity_prime_data &&
          item.entity_prime_data[key]
        ) {
          name =
            item.entity_prime_data[key + "/name"] ||
            item.entity_prime_data[key];
        }
        if (
          primaryField?.inputType == "WEEKDAYS" &&
          this.weekDayMap &&
          this.weekDayMap[name]
        ) {
          name = this.weekDayMap[name];
        }
      }
      if (name && typeof name == "object" && name.length) {
        return name.join(",");
      }
      return name;
    },
    entityDataTitle() {
      let name = "";
      if (this.getEntityPrimaryData) {
        let pr =
          this.getEntityDataById &&
          this.getEntityDataById.primaryFields &&
          this.getEntityDataById.primaryFields[0]
            ? this.getEntityDataById.primaryFields[0]
            : null;
        if (pr) {
          let obj = {},
            dt = {};
          (this.getEntityPrimaryData.templates_data || []).forEach((te) => {
            dt[te.template_id] = te?.template_data_id?.template_data;
          });
          obj = { ...this.getEntityPrimaryData, ...{ ["entityData"]: dt } };
          name = this.getPrimaryName(pr, obj);
        }
      }
      return name;
    },

    async fetchEntitiesDataForTable(dataIds = []) {
      if (this.relationshipDetails?.allowOne) {
        this.loading = true;
        if (dataIds.length) {
          await this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
            id: dataIds[0],
          });
          this.allowOneData = this.getEntityPrimaryData;
        }
        this.loading = false;
      } else {
        this.loading = true;
        let params = {
          entity_id: this.childEntityDataById._id,
          template_fields_data: this.childEntityDataById.templates.map(
            (template) => {
              return {
                template_id: template.template_id,
              };
            }
          ),
          filters: [
            ...(this.currentFilter?.filters || []),
            ...this.appliedQuickFilters,
          ],
          search_string: this.search_string,
          limit: this.pageSize,
          page: this.currentPage,
          total: this.relationshipDetails?.permissions?.total,
          ...(this.relationshipDetails?.aggregations?.length && {
            aggregations: this.relationshipDetails?.aggregations || [],
            ...(this.relationshipDetails?.filter && {
              filter_id: this.relationshipDetails?.filter,
            }),
          }),
        };

        if (dataIds.length) {
          params.entity_data_ids = dataIds;
        }
        let response = await postAPICall(
          "POST",
          "/entities-data/entity/data",
          params
        );
        this.data = [];
        if (response?.data?.length) {
          this.data = response?.data;
          this.mapEntityDataWithUserInfo(this.data).then((e) => {
            this.entityUserInfoData = { ...e };
          });
          this.numberOfRecords = this.data.length;
          this.total = response.total ? response.total : 0;
          if (
            this.relationshipDetails?.permissions?.total == true &&
            response.pageTotal !== null &&
            response.grandTotal !== null
          ) {
            this.data.push(response.pageTotal);
            this.data.push(response.grandTotal);
          }
          if (
            this.relationshipDetails?.aggregations?.length &&
            this.data.length
          ) {
            this.aggregations = [...response.aggregations];
            this.fetchAggregationValues();
          }
          this.loading = false;
        } else {
          this.loading = false;
        }
      }
    },
    fetchAggregationValues() {
      (this?.aggregations || []).map((agg) => {
        if (agg.type == "Display") {
          this.aggregationValues[agg.label] = Number(agg.count).toFixed(2);
        } else {
          let expr = "";
          agg.selected_fields.map((fd) => {
            if (fd.type == "FIELD") {
              expr += Number(fd.count);
            } else {
              expr += fd.value;
            }
          });
          try {
            this.aggregationValues[agg.label] = mexp.eval(expr).toFixed(2);
          } catch (err) {
            this.aggregationValues[agg.label] = "0.00";
          }
        }
        this.aggregationValues[agg.label] = this.getValidAggregationValues(
          this.aggregationValues[agg.label],
          agg.result_type
        );
      });
    },
    getValidAggregationValues(value, result_type) {
      if (result_type == "PERCENTAGE") {
        return !isNaN(value) ? value + "%" : "-";
      } else {
        return !isNaN(value) ? value : "-";
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key?.split("#").length == 1) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    async onDetachEntityData(row) {
      this.$confirm("Are you sure to remove the data?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.detachEntityData(row);
      });
    },
    async fetchAllEmployee() {
      this.loading = true;
      this.data = [];

      let params = {
        limit: this.pageSize,
        page: this.currentPage,
        entity_data_ids:
          this.parentRelatedChildEntityData.child_entity_data_ids,
      };

      await this.$store.dispatch("entities/fetchEntityDataById", {
        id: this.relationshipDetails.relationship_id,
        params,
      });
      this.loading = false;
      if (this.getEntityDataByEntityId) {
        this.currentPage = parseInt(this.getEntityDataByEntityId.page) || 1;
        this.total = parseInt(this.getEntityDataByEntityId.total) || 0;
        this.getEntityDataByEntityId.data &&
        this.getEntityDataByEntityId.data.length
          ? (this.data = this.getEntityDataByEntityId.data)
          : (this.data = []);
      }
    },

    backToEntities() {
      this.$router.push({ path: "/entity" });
      // this.$router.go(-1);
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        // await this.fetchAllEmployee();
        await this.fetchEntitiesDataForTable(
          this.parentRelatedChildEntityData.child_entity_data_ids
        );
      }
    },
    async viewWorkUserData(row) {
      if (row._id) {
        this.entityDataId = row._id;
        this.$router.push(
          `/entity_data_view/${this.entity_id}?dataId=${row._id}`
        );
      }
    },
    async viewEntityDataInPopup(row) {
      if (row._id) {
        this.entityData = row;
        this.showEntityDataInPopup = true;
      }
    },
    async editWorkUserData(row) {
      if (row._id) {
        let path = `/entityData-Edit/${this.entity_id}?dataId=${row._id}`;
        if (this.$route.path !== path) this.$router.push(path);
        // this.$router.push(

        // );
      }
    },
    getEntityName() {
      return this.getEntityDataById?.name;
    },

    async detachEntityData(entityData) {
      let params;

      if (this.relationshipDetails.representation == "TAB") {
        if (this.relationshipDetails.owner_type == "CHILD") {
          params = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id: entityData._id,
            child_entity_data_ids: [
              this.relationshipDetails.parent_entity_data_id,
            ],
          };
        } else {
          params = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_data_ids: [entityData._id],
          };
        }
      } else {
        params = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
          parent_entity_data_id: entityData._id,
          child_entity_data_ids: [
            this.relationshipDetails.parent_entity_data_id,
          ],
        };
      }

      if (
        this.relationshipDetails.representation == "FIELD" &&
        this.allowedDeleteForFieldRelationship
      ) {
        params.delete_field_data = this.removableFieldsKeys;
      }

      await this.$store.dispatch(
        "entityRelationships/detachEntityRelationshipData",
        params
      );
      if (this.getEntityRelationshipDataDetachStatus) {
        this.$notify.success({
          title: "Success",
          message: "Entity Data detached successfully",
        });
        await this.fetchParentRelatedChildEntityData();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getError || "Error on deleting Entity data",
        });
      }
    },
    async settingsPopUp() {
      this.dialogTableVisible = true;
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: "region" }]);
      }
      if (node.level > 1) return resolve([]);
    },

    goToEntityData(row) {
      // if (this.isApplicationUserSide) {
      //   let routerData = this.$router.resolve(
      //     `/ap/entity-view/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}&close_tab=true&type=AUfYWb7SC6U=`
      //   );
      //   window.open(routerData.href, "_blank");
      // } else {
      //   let routerData = this.$router.resolve(
      //     `/entity/view/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}`
      //   );
      //   window.open(routerData.href, "_blank");
      // }
      this.relationshipDataId = row._id;
      this.showRelationshipDataDialog = true;
    },
    redirectToEntityData(row) {
      if (this.isApplicationUserSide) {
        let routerData = this.$router.resolve(
          `/ap/entity-view/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}&close_tab=true&type=AUfYWb7SC6U=`
        );
        window.open(routerData.href, "_blank");
      } else {
        let routerData = this.$router.resolve(
          `/entity/view/${this.relationshipDetails.relationship_id}?dataId=${row._id}&relationship_id=${this.$route.params.entity_id}`
        );
        window.open(routerData.href, "_blank");
      }
    },
    checkAddNewPermission() {
      if (
        this.relationshipDetails &&
        Object.keys(this.relationshipDetails).includes("permissions")
      ) {
        if (this.relationshipDetails.permissions.add == true) {
          return true;
        }
        return false;
      }
      return true;
    },
    checkRedirectPermission() {
      if (
        this.relationshipDetails &&
        Object.keys(this.relationshipDetails).includes("permissions")
      ) {
        if (this.relationshipDetails.permissions.redirect == true) {
          return true;
        }
        return false;
      }
      return true;
    },
    checkViewPermission() {
      if (
        this.relationshipDetails &&
        Object.keys(this.relationshipDetails).includes("permissions")
      ) {
        if (this.relationshipDetails.permissions.view == true) {
          return true;
        }
        return false;
      }
      return true;
    },
    checkAssignPermission() {
      if (
        this.relationshipDetails &&
        Object.keys(this.relationshipDetails).includes("permissions")
      ) {
        if (this.relationshipDetails.permissions.assign == true) {
          return true;
        }
        return false;
      }
      return true;
    },
    downloadDocumentsPopup(row, step) {
      this.documentsData =
        row.entityData[step.template_id][step.id].files &&
        row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      this.downloadDocuments = true;
      //this.downloadDocuments = true
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
    async onPreview(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      let newWindow = open(
        this.getDownloadUrl,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function () {};
    },
    async onDownloadFile(data) {
      this.downloadLoading = true;
      this.loadingText = "Downloading...";
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.downloadLoading = false;
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
      this.downloadLoading = false;
    },
    getFieldValue1(field, value) {
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        let locale = field.validations.locale
          ? field.validations.locale
          : "en-US";
        value = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      } else if (
        field?.inputType == "AGGREGATE_FUNCTION" ||
        field?.inputType == "FORMULA"
      ) {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (
            el?.inputType == "CURRENCY" ||
            el?.input_type == "CURRENCY" ||
            el?.result_type == "CURRENCY"
          ) {
            return true;
          }
        });
        if (isCurrencyField) {
          if (field?.inputType == "AGGREGATE_FUNCTION") {
            let dataTableField = this.getField(
              field.template_id + "#" + isCurrencyField.data_table_key
            );
            if (dataTableField) {
              let rField = (dataTableField.data_table_columns || []).find(
                (e) => e.key == isCurrencyField.key
              );
              if (rField?.validations) {
                isCurrencyField["validations"] = rField.validations;
              }
            }
          } else {
            if (field?.validations) {
              isCurrencyField["validations"] = field.validations;
            }
          }
          let type =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.currency
              ? isCurrencyField.validations.currency
              : "USD";
          let locale =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.locale
              ? isCurrencyField.validations.locale
              : "en-US";
          value = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: type,
          }).format(value);
        }
      }
      return value;
    },
    checkNavigationAction(rowData, stepData) {
      if (
        this.isValidURL(this.getFieldValue(rowData, stepData)) &&
        this.isValidHttpUrl(
          this.getValidUrl(this.getFieldValue(rowData, stepData))
        )
      ) {
        let url = this.getValidUrl(this.getFieldValue(rowData, stepData));
        window.open(url, "_blank");
      } else {
        this.$message.info("Invalid URL");
      }
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
  },

  beforeDestroy() {
    // this.$store.commit("entities/setEntityDataById", null, { root: true });
    // this.$store.commit("entities/setEntityDataByEntityId", null, {
    //   root: true,
    // });
    // this.$store.commit("entities/setEntityDataUpdateStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("templatesData/setTemplateDeleteStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("templatesData/setTemplateDeleteError", null, {
    //   root: true,
    // });
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableView {
  overflow-wrap: normal;
  word-break: keep-all;
  min-width: max-content;
}
.sidemenu {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: -3%;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
}
.add-buttons {
  border: 1.5px solid #285ed3;
  box-sizing: border-box;
  border-radius: 2px;
  // padding: 0.45em 0.65em;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
  color: #285ed3;
  &:hover {
    background-color: #285ed3;
    color: #ffffff;
    border-color: #285ed3;
  }
}
#entity-users-list {
  // width: 90%;
  // display: table;
  margin: 10px 10px;
  .el-dialog__body {
    padding: 20px 20px;
    .el-card {
      .el-card__body {
        padding: 10px;
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .inner-navbar {
    margin-top: 10px;
  }
}
.doc {
  min-width: 30px;
  &.selected {
    background: green;
  }
}

.green {
  color: green;
}

.font-17 {
  font-size: 17px;
  cursor: pointer;
}

.documents {
  min-height: 200px;
  max-height: 400px;
  overflow: scroll;
}
</style>
<style lang="scss">
.el-table .cell {
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
}
.el-table .td {
  word-wrap: break-word;
}
.pagination-bar {
  .el-pagination {
    .el-pagination__jump {
      @media (max-width: 450px) {
        display: none;
      }
    }
  }
}
.template-container {
  border: 2px solid #e6e6e6;
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 20px;
}
.add-buttons {
  border: 1.5px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 2px;
  // padding: 0.45em 0.65em;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
  color: var(--primary-color);
  &:hover {
    background-color: var(--primary-color);
    color: #ffffff;
    border-color: var(--primary-color);
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
